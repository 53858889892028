<template>
    <div class="d-flex flex-row">
        <div class="flex-fill">
            <button class="btn flex-fill">
                <svg
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    class="bi bi-suit-heart"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill-rule="evenodd"
                        d="M8 6.236l.894-1.789c.222-.443.607-1.08 1.152-1.595C10.582 2.345 11.224 2 12 2c1.676 0 3 1.326 3 2.92 0 1.211-.554 2.066-1.868 3.37-.337.334-.721.695-1.146 1.093C10.878 10.423 9.5 11.717 8 13.447c-1.5-1.73-2.878-3.024-3.986-4.064-.425-.398-.81-.76-1.146-1.093C1.554 6.986 1 6.131 1 4.92 1 3.326 2.324 2 4 2c.776 0 1.418.345 1.954.852.545.515.93 1.152 1.152 1.595L8 6.236zm.392 8.292a.513.513 0 0 1-.784 0c-1.601-1.902-3.05-3.262-4.243-4.381C1.3 8.208 0 6.989 0 4.92 0 2.755 1.79 1 4 1c1.6 0 2.719 1.05 3.404 2.008.26.365.458.716.596.992a7.55 7.55 0 0 1 .596-.992C9.281 2.049 10.4 1 12 1c2.21 0 4 1.755 4 3.92 0 2.069-1.3 3.288-3.365 5.227-1.193 1.12-2.642 2.48-4.243 4.38z"
                    />
                </svg>
                {{ aggregateLikes }}
            </button>
        </div>
        <div class="flex-fill">
            <button v-if="saved" class="btn flex-fill">
                <svg
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    class="bi bi-bookmark-fill"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill-rule="evenodd"
                        d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.74.439L8 13.069l-5.26 2.87A.5.5 0 0 1 2 15.5V2z"
                    />
                </svg>
                Saved
            </button>
            <button
                v-else-if="saved === false"
                class="btn flex-fill"
                v-on:click="save"
            >
                <svg
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    class="bi bi-bookmark"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill-rule="evenodd"
                        d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.777.416L8 13.101l-5.223 2.815A.5.5 0 0 1 2 15.5V2zm2-1a1 1 0 0 0-1 1v12.566l4.723-2.482a.5.5 0 0 1 .554 0L13 14.566V2a1 1 0 0 0-1-1H4z"
                    />
                </svg>
                Save
            </button>
        </div>
        <div class="flex-fill">
            <button class="btn flex-fill" v-on:click="share">
                <svg
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    class="bi bi-box-arrow-up"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill-rule="evenodd"
                        d="M3.5 6a.5.5 0 0 0-.5.5v8a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5v-8a.5.5 0 0 0-.5-.5h-2a.5.5 0 0 1 0-1h2A1.5 1.5 0 0 1 14 6.5v8a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 14.5v-8A1.5 1.5 0 0 1 3.5 5h2a.5.5 0 0 1 0 1h-2z"
                    />
                    <path
                        fill-rule="evenodd"
                        d="M7.646.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 1.707V10.5a.5.5 0 0 1-1 0V1.707L5.354 3.854a.5.5 0 1 1-.708-.708l3-3z"
                    />
                </svg>
                Share
            </button>
        </div>
    </div>
</template>

<script>
/**
 * Component to create a like, share and save functionality of the recipe.
 */

export default {
    name: "Like-Share-Save",
    props: {
        // likes aggregated by the recipe
        aggregateLikes: {
            type: Number,
            required: true,
            default: 0
        },
        // flag if recipe is saved in favorites
        saved: {
            type: Boolean,
            required: true,
            default: false
        }
    },
    methods: {
        // save recipe to favorite list
        save: function() {
            /**
             * Triggers when save button was clicked.
             */
            this.$emit("saved");
        },

        // share recipe url with web share api
        share: function() {
            if (navigator.share) {
                navigator
                    .share({
                        title: "My awesome post!",
                        text:
                            "This post may or may not contain the answer to the universe",
                        url: window.location.href
                    })
                    .then(() => {
                        console.log("Thanks for sharing!");
                    })
                    .catch(err => {
                        console.log("Couldn't share because of", err.message);
                    });
            } else {
                console.log("web share not supported");
            }
        }
    }
};
</script>
